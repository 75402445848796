<template>
  <div class="p-grid crud-demo d-flex justify-content-center">
    <div class="p-col-12">
      <div class="card">
        <Toolbar class="p-mb-4">
          <template v-slot:left> Tracking Gadget @imikaempire</template>
          <template v-slot:right>
            <div>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <!-- <InputText
                  v-model="filters.value"
                  style="width: 100%"
                  placeholder="Tracking Code"
                /> -->
                <InputText
                  v-model.trim="receipt_code"
                  style="width: 100%"
                  placeholder="Receipt Code"
                />
              </span>
              <Button label="Search" class="p-button" @click="Track" />
            </div>
          </template>
        </Toolbar>
        <div class="spinner-border text-primary" v-if="loadingTrack"></div>
        <div class="device" v-if="!loadingTrack && receipt !== null">
          <h5>
            Tracking Code - {{ receipt.receiptCode }} - (Handled by:
            {{ receipt.receiverName }})
          </h5>
          <div v-for="(device, index) in devices" :key="device">
            <br />
            <h5>
              ({{ device.color.name }}) {{ device.brand.name }}
              {{ device.model }}
            </h5>
            <div
              v-for="(serviceStatusTech, index2) in device.serviceStatusTechs"
              :key="serviceStatusTech"
            >
              <div class="p-grid p-align-center vertical-container">
                <!-- <div class="p-col-3">
            <div class="box">3</div>
          </div> -->
                <div class="p-col-12" style="padding: 10px">
                  <div class="box">
                    <h5>
                      {{ serviceStatusTech.name }} ({{
                        serviceStatusTech.category
                      }}): {{ value[index][index2] }} %
                    </h5>
                    <u>Status </u><br />
                    <i>{{
                      computedServiceStatusTech(
                        serviceStatusTech,
                        index,
                        index2,
                      )
                    }}</i
                    ><br /><br />
                    <!-- <u>Date (Time) - Expected </u><br />
                    <i>23/03/2021, Wed (12:30 p.m)</i><br /><br />
                    <u>Date (Time) - Real</u><br />
                    <i>23/03/2021, Wed (02:30 p.m)</i><br /><br /> -->
                    <Slider
                      class="p-slider-handle"
                      :class="{
                        '--done':
                          Math.trunc(
                            value[index][index2] /
                              (100 / serviceStatusTech.list.length),
                          ) < serviceStatusTech.statusStage,
                        '--current':
                          Math.trunc(
                            value[index][index2] /
                              (100 / serviceStatusTech.list.length),
                          ) == serviceStatusTech.statusStage,
                        '--future':
                          Math.trunc(
                            value[index][index2] /
                              (100 / serviceStatusTech.list.length),
                          ) > serviceStatusTech.statusStage,
                      }"
                      v-model="value[index][index2]"
                      :step="100 / serviceStatusTech.list.length"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!loadingTrack && receipt === null">No Record Found</div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Slider from 'primevue/slider';
import Toolbar from 'primevue/toolbar';

export default {
  components: { InputText, Slider, Toolbar },
  data() {
    return {
      receipt_code: '',
      loadingTrack: false,
      // device:{},
      devices: [],
      filters: [],
      value: [[], []],
      serviceStatusTech: {},
      // valueChange: 50,
    };
  },
  mounted() {
    // let self = this;
    // self.$store.dispatch("getReceipts");
    // self.$store.dispatch("getServiceStatusTechs");
    // if (this.receipt !== null) {
    //   this.Track();
    // }
    // console.log(this.receipt);
  },
  computed: {
    receipt() {
      return this.$store.getters['receipt/receipt'];
    },
    receipts() {
      return this.$store.getters['receipt/receipts'];
    },
    serviceStatusTechs() {
      return this.$store.getters['device/serviceStatusTechs'];
    },
    computedServiceStatusTech() {
      return (serviceStatusTech, index, index2) => {
        // return serviceStatusTech.list[this.value[index][index2] / 10 - 1];
        if (100 / serviceStatusTech.list.length > this.value[index][index2]) {
          return serviceStatusTech.list[
            100 / serviceStatusTech.list.length / this.value[index][index2] - 1
          ];
        } else {
          return serviceStatusTech.list[
            this.value[index][index2] / (100 / serviceStatusTech.list.length) -
              1
          ];
        }
      };
    },
    // valueInitial() {
    //   return (serviceStatusTech) => {
    //     var computedValue;
    //     computedValue = serviceStatusTech.statusStage * 10;
    //     return computedValue;
    //   };
    // },
  },
  methods: {
    Track() {
      let self = this;
      this.loadingTrack = true;
      self.$store.dispatch('device/getServiceStatusTechs');
      self.$store
        .dispatch('receipt/getReceiptTrack', {
          receiptCode: this.receipt_code,
        })
        .then(function () {
          if (self.receipt !== null && self.serviceStatusTechs) {
            self.List();
          }
          self.loadingTrack = false;
        });

      // return temp2;
    },
    List() {
      var temp;
      var temp2 = [];

      for (var m = 0; m < this.receipt.deviceDetails.length; m++) {
        var device = this.receipt.deviceDetails[m];
        temp2[m] = device;
        temp2[m].serviceStatusTechs = [];

        for (var i = 0; i < device.technicalServiceDetails.length; i++) {
          for (var x = 0; x < this.serviceStatusTechs.length; x++) {
            temp = {};
            temp['name'] = null;
            temp['category'] = null;
            temp['statusStage'] = null;
            temp['list'] = [];
            var category1 =
              device.technicalServiceDetails[i].category.toUpperCase();
            var category2 = this.serviceStatusTechs[x].category.toUpperCase();
            if (category1 === category2) {
              temp.name = device.technicalServiceDetails[i].name;
              temp.statusStage = device.technicalServiceDetails[i].statusStage;
              temp.category = this.serviceStatusTechs[x].category;
              temp.list = this.serviceStatusTechs[x].statusList;
              this.value[m][i] =
                (100 / this.serviceStatusTechs[x].statusList.length) *
                temp.statusStage;
              temp2[m].serviceStatusTechs.push(temp);
              break;
            }
          }
        }
        for (var j = 0; j < device.sparePartDetails.length; j++) {
          for (var k = 0; k < this.serviceStatusTechs.length; k++) {
            temp = {};
            temp['name'] = null;
            temp['category'] = null;
            temp['statusStage'] = null;
            temp['list'] = [];
            category1 = device.sparePartDetails[j].category.toUpperCase();
            category2 = this.serviceStatusTechs[k].category.toUpperCase();
            if (category1 === category2) {
              temp.name = device.sparePartDetails[j].name;
              temp.statusStage = device.sparePartDetails[j].statusStage;
              temp.category = this.serviceStatusTechs[k].category;
              temp.list = this.serviceStatusTechs[k].statusList;
              // this.value=this.valueInitial(temp);
              this.value[m][device.technicalServiceDetails.length + j] =
                (100 / this.serviceStatusTechs[k].statusList.length) *
                temp.statusStage;
              // this.value[m][j] = temp.statusStage * 10;
              // console.log("m"+m+"i-"+device.services.length);
              temp2[m].serviceStatusTechs.push(temp);
              break;
            }
          }
        }
      }
      this.devices = temp2;
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  background-color: #ffffff;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.vertical-container {
  margin: 0;
  height: 100%;
  background: #dee2e6;
  border-radius: 4px;
}

::v-deep(.p-slider-handle) {
  &.--current {
    .p-slider-handle {
      border: 2px solid blue;
    }
  }
  &.--done {
    .p-slider-handle {
      background: green;
      border: 2px solid rgb(62, 197, 0);
    }
  }
  &.--future {
    .p-slider-handle {
      background: red;
      border: 2px solid red;
    }
  }
}
</style>
